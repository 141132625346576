<template>
  <div class="text-center">
    <v-dialog
        v-model="dialog"
        :width="$vuetify.breakpoint.mobile ? '500' : '1000'"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn block color="red lighten-2" dark v-bind="attrs" v-on="on">
          View Terms
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h4 text-uppercase grey lighten-2">
          Terms And Conditions
        </v-card-title>

        <v-card-text class="mt-5">
          <p>
            These terms and conditions (the "Terms and Conditions") govern the
            use of https://sellmycarparts.com.au/ (the "Site"). This Site is
            owned and operated by Ken Higgins. This Site is an ecommerce
            website. By using this Site, you indicate that you have read and
            understand these Terms and Conditions and agree to abide by them at
            all times.
          </p>
          <h4 class="text-h5 text-uppercase">Intellectual Property:</h4>
          <p>
            All content published and made available on our Site is the property
            of Ken Higgins and the Site's creators. This includes, but is not
            limited to images, text, logos, documents, downloadable files and
            anything that contributes to the composition of our Site.
          </p>
          <h4 class="text-h5 text-uppercase">Age Restrictions:</h4>
          <p>
            The minimum age to use our Site is 16 years old. By using this Site,
            users agree that they are over 16 years old. We do not assume any
            legal responsibility for false statements about age.
          </p>
          <h4 class="text-h5 text-uppercase">Acceptable Use:</h4>
          <p>
            As a user of our Site, you agree to use our Site legally, not to use
            our Site for illegal purposes, and not to:
          </p>
          <ul>
            <li>Harass or mistreat other users of our Site;</li>
            <li>Violate the rights of other users of our Site;</li>
            <li>
              Violate the intellectual property rights of the Site owners or any
              third party to the Site;
            </li>
            <li>Act in any way that could be considered fraudulent;</li>
            <li>
              Post any material that may be deemed inappropriate or offensive;
              or
            </li>
            <li>Listing off-topic items.</li>
          </ul>
          <p>
            If we believe you are using our Site illegally or in a manner that
            violates these Terms and Conditions, we reserve the right to limit,
            suspend or terminate your access to our Site. We also reserve the
            right to take any legal steps necessary to prevent you from
            accessing our Site.
          </p>
          <h4 class="text-h5 text-uppercase">User Contributions:</h4>
          <p>Users may post the following information on our Site:</p>
          <ul>
            <li>Items for sale;</li>
            <li>Photos;</li>
            <li>Videos;</li>
            <li>Public comments; and</li>
            <li>Contact Information.</li>
          </ul>
          <p>
            By posting publicly on our Site, you agree not to act illegally or
            violate these Terms and Conditions.
          </p>
          <h4 class="text-h5 text-uppercase">Accounts:</h4>
          <p>
            When you create an account on our Site, you agree to the following:
          </p>
          <ol>
            <li>
              You are solely responsible for your account and the security and
              privacy of your account, including passwords or sensitive
              information attached to that account; and
            </li>
            <li>
              All personal information you provide to us through your account is
              up to date, accurate, and truthful and that you will update your
              personal information if it changes.
            </li>
          </ol>
          <p>
            We reserve the right to suspend or terminate your account if you are
            using our Site illegally or if you violate these Terms and
            Conditions.
          </p>
          <h4 class="text-h5 text-uppercase">Sale of Goods:</h4>
          <p>
            These Terms and Conditions govern the sale of goods available on our
            Site. The Site is a Marketplace, acting as a place to connect Buyers
            (those purchasing goods) and Sellers (those providing the goods).
            The Site is not responsible for individual listings, and any sales
            made are made between Buyer and Seller. If you believe a Seller on
            the Site is operating in violation of Australian Consumer Law or our
            Terms and Conditions, then we can be notified at
            support@sellmycarparts.com.au, and we will investigate your
            concerns. These Terms and Conditions apply to all the goods that are
            displayed on our Site at the time you access it. This includes all
            products listed as being out of stock. All information,
            descriptions, or images that are provided about our goods must be as
            accurate as possible. However, we are not legally bound by such
            information, descriptions, or images as we cannot guarantee the
            accuracy of goods listed on our site. You agree to purchase goods
            through our Site at your own risk. Our Site offers goods and
            services from third parties. We cannot guarantee the quality or
            accuracy of goods and services made available by third parties on
            our Site. We reserve the right to modify, reject or cancel your
            order whenever it becomes necessary. If we cancel your order and
            have already processed your payment, we will give you a refund equal
            to the amount you paid. You agree that it is your responsibility to
            monitor your payment instrument to verify receipt of any refund.
          </p>
          <h4 class="text-h5 text-uppercase">User Goods and Services:</h4>
          <p>
            Our Site allows users to sell goods and services. We do not assume
            any responsibility for the goods and services users sell on our
            Site. We cannot guarantee the quality or accuracy of any goods and
            services sold by users on our Site. However, if we are made aware
            that a user is violating these Terms and Conditions or Australian
            Consumer Law, we reserve the right to suspend or prohibit the user
            from selling goods and services on our Site.
          </p>
          <h4 class="text-h5 text-uppercase">Subscriptions:</h4>
          <p>
            Your subscription automatically renews and you will be automatically
            billed until we receive notification that you want to cancel the
            subscription. You may cancel at any time.
          </p>
          <h4 class="text-h5 text-uppercase">Payments:</h4>
          <p>
            Payments are processed through Stripe. When you provide us with your
            payment information, you authorise our use of and access to the
            payment instrument you have chosen to use. By providing us with your
            payment information, you authorise us to charge the amount due to
            this payment instrument. If we believe your payment has violated any
            law or these Terms and Conditions, we reserve the right to cancel or
            reverse your transaction.
          </p>
          <h4 class="text-h5 text-uppercase">Shipping and Delivery:</h4>
          <p>
            All shipping and delivery is handled by users and third party
            companies. The Site, Owners, Employees and Contractors are not
            responsible for any issues that arise during Shipping and Delivery
            of any purchased goods.
          </p>
          <h4 class="text-h5 text-uppercase">Consumer Protection Law:</h4>
          <p>
            Where the Australian Consumer Law, Schedule 2 of the Competition and
            Consumer Act 2010, or any other consumer protection legislation in
            your jurisdiction applies and cannot be excluded, these Terms and
            Conditions will not limit your legal rights and remedies under that
            legislation. These Terms and Conditions will be read subject to the
            mandatory provisions of that legislation. If there is a conflict
            between these Terms and Conditions and that legislation, the
            mandatory provisions of the legislation will apply.
          </p>
          <h4 class="text-h5 text-uppercase">Links to Other Websites:</h4>
          <p>
            Our Site contains links to third party websites or services that we
            do not own or control. We are not responsible for the content,
            policies, or practices of any third party website or service linked
            to on our Site. It is your responsibility to read the terms and
            conditions and privacy policies of these third party websites before
            using these sites.
          </p>
          <h4 class="text-h5 text-uppercase">Limitation of Liability:</h4>
          <p>
            Ken Higgins and our directors, officers, agents, employees,
            subsidiaries, and affiliates will not be liable for any actions,
            claims, losses, damages, liabilities and expenses including legal
            fees from your use of the Site.
          </p>
          <h4 class="text-h5 text-uppercase">Indemnity:</h4>
          <p>
            Except where prohibited by law, by using this Site you indemnify and
            hold harmless Ken Higgins and our directors, officers, agents,
            employees, subsidiaries, and affiliates from any actions, claims,
            losses, damages, liabilities and expenses including legal fees
            arising out of your use of our Site or your violation of these Terms
            and Conditions.
          </p>
          <h4 class="text-h5 text-uppercase">Applicable Law:</h4>
          <p>
            These Terms and Conditions are governed by the laws of the State of
            New South Wales.
          </p>
          <h4 class="text-h5 text-uppercase">Severability:</h4>
          <p>
            If at any time any of the provisions set forth in these Terms and
            Conditions are found to be inconsistent or invalid under applicable
            laws, those provisions will be deemed void and will be removed from
            these Terms and Conditions. All other provisions will not be
            affected by the removal and the rest of these Terms and Conditions
            will still be considered valid.
          </p>
          <h4 class="text-h5 text-uppercase">Changes:</h4>
          <p>
            These Terms and Conditions may be amended from time to time in order
            to maintain compliance with the law and to reflect any changes to
            the way we operate our Site and the way we expect users to behave on
            our Site. We will notify users by email of changes to these Terms
            and Conditions or post a notice on our Site.
          </p>
          <h4 class="text-h5 text-uppercase">Contact Details:</h4>
          <p>
            Please contact us if you have any questions or concerns. Our contact
            details are as follows: support@sellmycarparts.com.au 15 Webster Dr,
            Swan Hill VIC 3585 You can find these details on our site as well.
            Effective Date: 6th day of February, 2022
          </p>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
  }),
};
</script>