<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card
            :class="$vuetify.breakpoint.mobile ? 'pa-2 pt-3 mt-3' : 'pa-10 mt-5'"
            class="mx-auto pa-2 my-5"
            color="secondary"
            flat
        >
          <v-card-title>
            <v-row>
              <v-col
              ><h2
                  :class="$vuetify.breakpoint.smAndDown ? 'text-center' : ''"
                  class="white--text text-h2 font-weight-bold word-wrapper"
              >
                Sign Up
              </h2></v-col
              >
              <v-col>
                <div :style="!$vuetify.breakpoint.mobile ? 'float: right' : ''">
                  <h3 class="white--text">Link account to</h3>
                  <div class="d-inline-flex">
                    <v-btn class="mr-2 white--text" color="primary" disabled
                    >
                      <v-icon>mdi-google</v-icon>
                      Google
                    </v-btn
                    >
                    <v-btn class="mr-2 white--text" color="primary" disabled
                    >
                      <v-icon>mdi-facebook</v-icon>
                      Facebook
                    </v-btn
                    >
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-card-title>
          <v-form ref="form" @submit.prevent>
            <v-card-text>
              <div class="grey lighten-3 rounded-lg pa-5 pt-4">
                <v-row>
                  <v-col :cols="!$vuetify.breakpoint.mobile ? 3 : 5">
                    <v-subheader>Username</v-subheader>
                  </v-col>
                  <v-col>
                    <v-text-field
                        v-model="username"
                        :error-messages="usernameErrors"
                        class="rounded-pill"
                        required
                        solo
                        @blur="$v.username.$touch()"
                        @input="$v.username.$touch()"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col :cols="!$vuetify.breakpoint.mobile ? 3 : 5">
                    <v-subheader>Email</v-subheader>
                  </v-col>
                  <v-col>
                    <v-text-field
                        v-model="email"
                        :error-messages="emailErrors"
                        class="rounded-pill"
                        required
                        solo
                        @blur="$v.email.$touch()"
                        @input="$v.email.$touch()"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col :cols="!$vuetify.breakpoint.mobile ? 3 : 5">
                    <v-subheader>Password</v-subheader>
                  </v-col>
                  <v-col>
                    <v-text-field
                        v-model="password"
                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        :error-messages="passwordErrors"
                        :type="showPassword ? 'text' : 'password'"
                        class="rounded-pill"
                        required
                        solo
                        @blur="$v.password.$touch()"
                        @focusin="isFocusedPwd = true"
                        @focusout="isFocusedPwd = false"
                        @input="$v.password.$touch()"
                        @click:append="showPassword = !showPassword"
                    />
                    <v-row v-if="isFocusedPwd" no-gutters>
                      <v-col cols="12" lg="6">
                        <v-row>
                          <v-col cols="1"><v-icon :class="passwordErrors.includes('Password must be longer than 6 Characters') || passwordErrors.includes('Password is required') ? 'red--text' : 'green--text'">{{passwordErrors.includes("Password must be longer than 6 Characters") || passwordErrors.includes("Password is required") ? "mdi-close" : "mdi-check"}}</v-icon></v-col>
                          <v-col><p class="grey--text">Is longer than 6 characters</p></v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" lg="6">
                        <v-row>
                          <v-col cols="1">
                            <v-icon :class="passwordErrors.includes('Password must contain at least one Capital Letter.') ? 'red--text' : 'green--text'">{{passwordErrors.includes("Password must contain at least one Capital Letter.") ? "mdi-close" : "mdi-check"}}</v-icon>
                          </v-col>
                          <v-col>
                            <p class="text-body-2 grey--text ligt">Contains a capital letter</p>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" lg="6">
                        <v-row>
                          <v-col cols="1">
                            <v-icon :class="passwordErrors.includes('Password must contain at least one number.') ? 'red--text' : 'green--text'">{{passwordErrors.includes("Password must contain at least one number.") ? "mdi-close" : "mdi-check"}}</v-icon>
                          </v-col>
                          <v-col>
                            <p class="text-body-2 grey--text ligt">Contains a number</p>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" lg="6">
                        <v-row>
                          <v-col cols="1">
                            <v-icon :class="passwordErrors.includes('Password must contain at least one special character (e.g. ! ? $).') ? 'red--text' : 'green--text'">{{passwordErrors.includes("Password must contain at least one special character (e.g. ! ? $).") ? "mdi-close" : "mdi-check"}}</v-icon>
                          </v-col>
                          <v-col>
                            <p class="text-body-2 grey--text ligt">Has a special character (e.g. ! ? $)</p>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col :cols="!$vuetify.breakpoint.mobile ? 3 : 5">
                    <v-subheader>Repeat Password</v-subheader>
                  </v-col>
                  <v-col>
                    <v-text-field
                        v-model="repeatPassword"
                        :error-messages="repeatPasswordErrors"
                        class="rounded-pill"
                        required
                        solo
                        :type="showPassword ? 'text' : 'password'"
                        @blur="$v.repeatPassword.$touch()"
                        @input="$v.repeatPassword.$touch()"
                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="showPassword = !showPassword"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col :cols="!$vuetify.breakpoint.mobile ? 3 : 12">
                    <v-subheader>Do you agree with our Terms of Use:</v-subheader>
                  </v-col>
                  <v-col>
                    <v-row>
                      <v-col cols="12" lg="6" md="6">
                        <TOS class="mb-2"/>
                        <PrivacyPolicy/>
                      </v-col>
                      <v-col cols="12" lg="6" md="6">
                        <v-checkbox
                            v-model="acceptTerms"
                            :error-messages="acceptTermsErrors"
                            label="Click the check box to agree"
                            required
                            @blur="$v.acceptTerms.$touch()"
                            @change="$v.acceptTerms.$touch()"
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-btn
                  :width="$vuetify.breakpoint.mobile ? '170px' : '250px'"
                  class="ml-2 white--text"
                  color="primary"
                  type="submit"
                  @click="registerUserCall"
              >Sign Up
              </v-btn
              >
              <v-btn :to="{ name: 'Login' }" class="mr-2 white--text" text>Login</v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapActions} from "vuex";
import TOS from "@/components/TOS.vue";
import PrivacyPolicy from "@/components/PrivacyPolicy.vue";
import {email, helpers, minLength, required, sameAs,} from "vuelidate/lib/validators";

// const strongPassword = helpers.regex(
//     "strongPassword",
//     /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{6,15}$/
// );
const hasNumber = helpers.regex(
    "hasNumber",
    /^(?=.*[0-9]).+$/
);
const hasSpecialCharacter = helpers.regex(
    "hasSpecialCharacter",
    /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/
);
const hasCapitalLetter = helpers.regex(
    "hasCapitalLetter",
    /^(?=.*[A-Z]).+$/
);
export default {
  metaInfo: {
    title: "Sign Up",
    "http-equiv": "Content-Security-Policy",
    content: "upgrade-insecure-requests",
  },
  components: {TOS, PrivacyPolicy},
  data: () => ({
    username: "",
    email: "",
    password: "",
    repeatPassword: "",
    acceptTerms: "",
    showPassword: false,
    isFocusedPwd: false,
  }),
  computed: {
    usernameErrors() {
      const errors = [];
      if (!this.$v.username.$dirty) return errors;
      !this.$v.username.required && errors.push("Username is required.");
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Must be valid e-mail");
      !this.$v.email.required && errors.push("E-mail is required");
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.minLength &&
      errors.push("Password must be longer than 6 Characters");
      !this.$v.password.hasCapitalLetter &&
      errors.push(
          "Password must contain at least one Capital Letter."
      );
      !this.$v.password.hasNumber &&
      errors.push(
          "Password must contain at least one number."
      );
      !this.$v.password.hasSpecialCharacter &&
      errors.push(
          "Password must contain at least one special character (e.g. ! ? $)."
      );
      !this.$v.password.required && errors.push("Password is required");
      return errors;
    },
    repeatPasswordErrors() {
      const errors = [];
      if (!this.$v.repeatPassword.$dirty) return errors;
      !this.$v.repeatPassword.sameAs && errors.push("Passwords must match");
      !this.$v.repeatPassword.required &&
      errors.push("You must repeat your password");
      return errors;
    },
    acceptTermsErrors() {
      const errors = [];
      if (!this.$v.acceptTerms.$dirty) return errors;
      !this.$v.acceptTerms.required &&
      errors.push("You must accept the Terms of Service");
      return errors;
    },
  },
  validations: {
    username: {
      required,
    },
    email: {
      required,
      email,
    },
    password: {
      required,
      minLength: minLength(6),
      hasNumber,
      hasCapitalLetter,
      hasSpecialCharacter
    },
    repeatPassword: {
      required,
      sameAs: sameAs("password"),
    },
    acceptTerms: {
      required,
    },
  },
  methods: {
    ...mapActions("alert", ["alerts"]),
    ...mapActions("user", ["registerUser"]),
    async registerUserCall() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const registerInfo = {
          username: this.username,
          email: this.email,
          pwd: this.password,
          pwdRepeat: this.repeatPassword,
          tos: this.acceptTerms,
        };
        let response = await this.registerUser(registerInfo);
        if (response["error"] === "none" || response["error"] === undefined) {
          this.alerts({
            type: "warning",
            msg: "You are now signed up, however go to settings to help verify your account with more information.",
          });
          await this.$router.push({name: "Dashboard"});
        } else if (response["error"] === "stmtFailed") {
          this.alerts({
            type: "error",
            msg: "Something has gone wrong on our end!",
          });
        } else if (response["error"] === "enterAllFields") {
          this.alerts({
            type: "warning",
            msg: "Please enter in all the information required",
          });
        } else if (response["error"] === "uidNoExist") {
          this.alerts({
            type: "error",
            msg: "User was not created in system, please try again",
          });
        } else if (response["error"] === "uidExist") {
          this.alerts({
            type: "warning",
            msg: "That username is already taken, try another username or forget password",
          });
        } else if (response["error"] === "invalidEmail") {
          this.alerts({
            type: "warning",
            msg: "That email address is not valid",
          });
        } else if (response["error"] === "shortUsername") {
          this.alerts({
            type: "warning",
            msg: "The username you provided is too short, it must be greater then 4 characters",
          });
        } else if (response["error"] === "weakPassword") {
          this.alerts({
            type: "warning",
            msg: "Password must include a lowercase, uppercase and special character (!, ?, #)",
          });
        } else if (response["error"] === "emailExist") {
          this.alerts({
            type: "warning",
            msg: "That email is already taken, please use other one",
          });
        } else {
          this.alerts({
            type: "error",
            msg: "An unknown error has occurred, please try again",
          });
        }
      }
    },
  },
};
</script>