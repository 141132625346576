<template>
  <div class="text-center">
    <v-dialog
        v-model="dialog"
        :width="$vuetify.breakpoint.mobile ? '500' : '1000'"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn block color="red lighten-2" dark v-bind="attrs" v-on="on">
          View Privacy Policy
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h4 text-uppercase grey lighten-2">
          Privacy Policy
        </v-card-title>

        <v-card-text class="mt-5 text-body-1">
          <h5 class="text-h5">https://sellmycarparts.com.au/</h5>
          <p>Privacy Policy Type of website: Ecommerce</p>
          <p>Effective date: 7th day of February, 2022</p>
          <p>
            https://sellmycarparts.com.au/ (the "Site") is owned and operated by
            Ken Higgins. Ken Higgins can be contacted at:
            support@sellmycarparts.com.au, 15 Webster Dr, Swan Hill VIC 3585
          </p>
          <p></p>
          <h4 class="text-h5 text-uppercase mt-3">Purpose:</h4>
          <p>
            The purpose of this privacy policy (this "Privacy Policy") is to
            inform users of our Site of the following:
          </p>
          <ol>
            <li>The personal data we will collect;</li>
            <li>Use of collected data;</li>
            <li>Who has access to the data collected;</li>
            <li>The rights of Site users; and</li>
            <li>The Site's cookie policy</li>
          </ol>
          <p>
            This Privacy Policy applies in addition to the terms and conditions
            of our Site.
          </p>
          <h4 class="text-h5 text-uppercase mt-3">Consent:</h4>
          <p>By using our Site users agree that they consent to:</p>
          <ol>
            <li>The conditions set out in this Privacy Policy; and</li>
            <li>
              The collection, use, and retention of the data listed in this
              Privacy Policy.
            </li>
          </ol>
          <h4 class="text-h5 text-uppercase mt-3">Personal Data We Collect:</h4>
          <p>
            We only collect data that helps us achieve the purpose set out in
            this Privacy Policy. We will not collect any additional data beyond
            the data listed below without notifying you first.
          </p>
          <h6 class="text-h6 text-uppercase mt-3">
            Data Collected Automatically
          </h6>
          <p>
            When you visit and use our Site, we may automatically collect and
            store the following information:
          </p>
          <ol>
            <li>Location; and</li>
            <li>Clicked Links.</li>
          </ol>
          <h6 class="text-h6 text-uppercase mt-3">
            Data Collected in a Non-Automatic Way
          </h6>
          <p>
            We may also collect the following data when you perform certain
            functions on our Site:
          </p>
          <ol>
            <li>First and last name;</li>
            <li>Age;</li>
            <li>Date of Birth;</li>
            <li>Sex/Gender;</li>
            <li>Email Address;</li>
            <li>Phone number;</li>
            <li>Home and/or Business Address;</li>
            <li>Payment Information; and</li>
            <li>Auto-fill data.</li>
          </ol>
          <p>This data may be collected using the following methods:</p>
          <ol>
            <li>Creating an account; and</li>
            <li>Purchasing Goods.</li>
          </ol>
          <h4 class="text-h5 text-uppercase mt-3">How we use personal data:</h4>
          <p>
            Data collected on our Site will only be used for the purposes
            specified in this Privacy Policy or indicated on the relevant pages
            of our Site. We will not use your data beyond what we disclose in
            this Privacy Policy.
          </p>
          <p>
            The data we collect automatically is used for the following
            purposes:
          </p>
          <ol>
            <li>Site Functionality</li>
          </ol>
          <p>
            The data we collect when the user performs certain functions may be
            used for the following purposes:
          </p>
          <ol>
            <li>Site Improvement; and</li>
            <li>Providing Services</li>
          </ol>
          <h4 class="text-h5 text-uppercase mt-3">
            Who we share personal data with:
          </h4>
          <h6 class="text-h6 text-uppercase mt-3">Employees</h6>
          <p>
            We may disclose user data to any member of our organisation who
            reasonably needs access to user data to achieve the purposes set out
            in this Privacy Policy.
          </p>
          <h6 class="text-h6 text-uppercase mt-3">Other Disclosures</h6>
          <p>
            We will not sell or share your data with other third parties, except
            in the following cases:
          </p>
          <ol>
            <li>If the law requires it;</li>
            <li>If it required for any legal proceeding;</li>
            <li>To prove or protect our legal rights; and</li>
            <li>
              To buyers or potential buyers of this company in the event that we
              seek to sell the company.
            </li>
          </ol>
          <p>
            If you follow hyperlinks from our Site to another Site, please note
            that we are not responsible for and have no control over their
            privacy policies and practices.
          </p>
          <h4 class="text-h5 text-uppercase mt-3">
            How long we store personal data:
          </h4>
          <p>
            User data will be stored until the purpose the data was collected
            for has been achieved. For site functionality, this means most data
            will be stored indefinitely for continued functionality.
          </p>
          <p>
            You will be notified if your data is kept for longer than this
            period.
          </p>
          <h4 class="text-h5 text-uppercase mt-3">
            how we protect your personal data:
          </h4>
          <p>
            All data is only accessible by the owner and developers of the site,
            other than data that is displayed as part of the function of the
            site. It is run off of a private server.
          </p>
          <p>
            While we take all reasonable precautions to ensure that user data is
            secure and that users are protected, there always remains the risk
            of harm. The Internet as a whole can be insecure at times and
            therefore we are unable to guarantee the security of user data
            beyond what is reasonably practical.
          </p>
          <h4 class="text-h5 text-uppercase mt-3">Children:</h4>
          <p>
            The minimum age to use our website is 16 years of age. We do not
            knowingly collect or use personal data from children under 16 years
            of age. If we learn that we have collected personal data from a
            child under 16 years of age, the personal data will be deleted as
            soon as possible. If a child under 16 years of age has provided us
            with personal data their parent or guardian may contact our privacy
            officer.
          </p>
          <h4 class="text-h5 text-uppercase mt-3">
            How to Access, Modify, Delete, or Challenge the Data collected:
          </h4>
          <p>
            If you would like to know if we have collected your personal data,
            how we have used your personal data, if we have disclosed your
            personal data and to who we disclosed your personal data, or if you
            would like your data to be deleted or modified in any way, please
            use the tools available through our site. If you need further
            clarification or assistance, contact us at
            support@sellmycarparts.com.au.
          </p>
          <h4 class="text-h5 text-uppercase mt-3">Cookie Policy:</h4>
          <p>
            A cookie is a small file, stored on a user's hard drive by a
            website. Its purpose is to collect data relating to the user's
            browsing habits. You can choose to be notified each time a cookie is
            transmitted. You can also choose to disable cookies entirely in your
            internet browser, but this may decrease the quality of your user
            experience.
          </p>
          <p>We use the following types of cookies on our Site:</p>
          <ol>
            <li>
              Functional cookies, are used to remember the selections you make
              on our Site so that your selections are saved for your next
              visits.
            </li>
          </ol>
          <h4 class="text-h5 text-uppercase mt-3">Modifications:</h4>
          <p>
            This Privacy Policy may be amended from time to time in order to
            maintain compliance with the law and to reflect any changes to our
            data collection process. When we amend this Privacy Policy we will
            update the "Effective Date" at the top of this Privacy Policy. We
            recommend that our users periodically review our Privacy Policy to
            ensure that they are notified of any updates. If necessary, we may
            notify users by email of changes to this Privacy Policy, or by a
            post to our Site.
          </p>
          <h4 class="text-h5 text-uppercase mt-3">Contact Information:</h4>
          <p>
            If you have any questions, concerns or complaints, you can contact
            us at: support@sellmycarparts.com.au
          </p>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
  }),
};
</script>